import React from "react"
import { Link } from "gatsby"

import Layout from "../../../components/customlayout"
import SEO from "../../../components/seo"
import AirwayHeader from "../../../components/airwayheader"

import styled from "styled-components"

export default function Indications(props) {
  return (
    <>
      <AirwayHeader></AirwayHeader>
      <AirwayContainer>
        <Layout
          title={
            "Seven P's of RSI - The Process of Rapid Sequence Induction and Intubation"
          }
        >
          <SEO
            title={
              "Seven P's of RSI - The Process of Rapid Sequence Induction and Intubation"
            }
            description={
              "Rapid sequence intubation (RSI) is an emergent procedure used to secure the airway in acutely unstable patients."
            }
            url={"https://www.nucleotype.com/airway/rsi-process"}
          />

          <p>
            Rapid sequence intubation (RSI) is an emergent procedure used to
            secure the airway in acutely unstable patients. It involves the use
            of an induction agent to induce unconsciousness, followed
            immediately by a neuromuscular blocking agent for paralysis so that
            the clinician can safely intubate the patient by minimizing the time
            the patient’s airway is unprotected.
          </p>

          <h2>Preparation</h2>
          <p>
            Start by assessing the patient for anatomical features or
            physiologic findings that would potentially make them difficult to
            intubate or ventilate using a bag-valve-mask. For example, obese
            patients are classically presumed to be difficult intubations
            because of their body habitus often making it difficult to maneuver
            the laryngoscope in order to locate your anatomical landmarks. Also
            consider physiological derangements such as hypotension or shock as
            these clinical scenarios may put patients at risk for
            post-intubation hypotension or cardiac arrest.
          </p>
          <p>
            These anatomically difficult patients and physiologic derangements
            may lead to complications that may prolong the time to successful
            intubation, thus prolonging the patient’s apneic time. Having a plan
            to correct these derragement prior to intubation may prevent these
            poor outcomes, further increasing the chance of a successful
            intubation on the first attempt.
          </p>
          <p>
            Have an airway management plan in place from the very beginning.
            This would include gathering the necessary equipment and
            medications, in addition to formulating a plan to correct
            physiologic derangements, such as intravenous fluids in septic
            shock, and optimizing positioning in cases of difficult anatomically
            airways.
          </p>
          <p>
            Have a backup plan to account for any potential difficulties or
            complications resulting from RSI. Remember that the goal of
            preparation is to maximize the chances for successful intubation on
            the first attempt.
          </p>

          <h2>Preoxygenation</h2>
          <p>
            Preoxygenation creates an oxygen reservoir in the lungs, blood, and
            tissues that enables patients to tolerate a longer period of apnea
            without desaturation. This can be achieved with flush-flow oxygen
            for at least three minutes and passive oxygenation via high-flow
            nasal cannula thereafter is recommended for all patients being
            intubated with RSI. In certain clinical scenarios, you may even
            consider the use of non-invasive ventilation to achieve a desirable
            oxygen reserve in order to facilitate a longer period of apnea
            without desaturation. Keep in mind that the time to desaturation can
            vary depending on age, gender and most importantly those who are
            critically ill. For example, a study demonstrated the time to
            desaturation less than 90 percent after apnea during RSI for
            preoxygenated patients of different ages and conditions:
          </p>
          <ul>
            <li>Healthy 70-kg male: 6 to 8 minutes</li>
            <li>Young children (10 kg): &lt;4 minutes</li>
            <li>Adults with chronic illness or obesity: &lt;3 minutes</li>
            <li>Women at near full-term pregnancy: &lt;3 minutes</li>
          </ul>
          <p>
            Critically ill patients in the ED or intensive care unit (ICU) often
            desaturate even more quickly, if not immediately.
          </p>

          <h2>Pre-intubation optimization</h2>
          <p>
            During the preparation phase, the provider should have recognized
            any anatomical features or physiological derangements that would
            make RSI more difficult.
          </p>
          <p>
            Unless the need for intubation is immediate, patients should be
            physiologically optimized prior to the procedure. This includes the
            identification of the underlying pathology and attempting to treat
            and manage the patient accordingly. This may include intravenous
            fluids, blood products, and vasopressors as necessary in cases of
            septic shock, or the placement of chest tubes in cases of
            hemopneumothorax in trauma and the continued preoxygenation for all
            patients.
          </p>

          <h2>Paralysis with induction</h2>
          <p>
            Your induction agent and paralytic of choice should have been chosen
            prior to this point and after optimizing your patient’s conditions,
            the induction agent and paralytic are administered almost
            simultaneously. The goal of this step during RSI is to achieve
            sedation and paralysis 45 to 60 seconds after the administration of
            the medications via IV push. Specific induction agents and
            neuromuscular blocking agents are discussed separately.
          </p>

          <h2>Protection and Positioning</h2>
          <p>
            For patients that are not immobilized (such as a cervical collar)
            are placed in a sniffing position, where the patient is supine with
            a 20 degree head-up position. In immobilized patients, 30 degree
            reverse Trendelenburg positioning may be used.
          </p>
          <p>
            After successful induction and paralysis, bag-valve-mask generally
            should not be performed in patients who were successfully
            preoxygenated to prevent the risk of regurgitation and aspiration.
          </p>
          <p>
            Cricoid pressure was previously recommended during RSI in the
            emergent setting, however this is no longer recommended. This
            maneuver was previously used to prevent gastric insufflation and the
            regurgitation of gastric contents during bag-mask ventilation,
            however evidence has been conflicting whether or not this maneuver
            is successful in achieving this. Additionally, if preoxygenation was
            performed successfully, bag-mask ventilation is unnecessary during
            this period of apnea during RSI.
          </p>
          <p>
            The downside in performing this maneuver is that it may cause some
            degree of airway obstruction making it more difficult to intubate.
            As a result, cricoid pressure during RSI in the emergent setting is
            generally no longer recommended.
          </p>

          <h2>Placement with proof</h2>
          <p>
            After the administration of your induction agent and paralytic,
            complete neuromuscular relaxation should be achieved within 45 to 60
            seconds, and at this point, laryngoscopy is performed. Once the
            glottis is visualized, the endotracheal tube (ETT) is passed between
            the vocal cords, the ETT cuff is inflated and the stylet is removed.
          </p>
          <p>
            The next step is to confirm the proper placement of the ETT within
            the airway and not mistakenly placed into the esophagus.
          </p>
          <p>
            ETT placement within the airway is confirmed by many different ways,
            however the most accurate way is with the use of waveform
            capnography. Other options for confirmation include the use of
            capnometry or colorimetric end-tidal CO2 devices. Adjuncts to these
            confirmation methods include the visualization of the ETT through
            the cords, condensation within the ETT with ventilation and the
            auscultation of breath sounds over lung fields. However these
            clinical indicators should not be used alone to confirm proper ETT
            placement. Additionally, single-view chest x-rays are often obtained
            after the placement of the ETT, however this is used to determine
            the depth of placement and should not be used to confirm proper
            placement of the ETT within the airway.
          </p>

          <h2>Postintubation Management</h2>
          <p>
            After confirming the placement of your ETT within the airway, your
            ETT is secured in place. This can be achieved by taping or tying the
            ETT or using specifically designed tube holders.
          </p>

          <p>
            As mentioned previously, a post-intubation chest radiograph is
            obtained to confirm the depth of the ETT and to assess for any
            barotrauma from positive pressure ventilation. The radiograph is not
            used to confirm the proper placement of the ETT within the airway
            because of the proximity of the esophagus to the trachea.Ocne proper
            depth of the ETT is confirmed, adjustments to the ventilator can be
            made according to the clinical circumstances of the patient.
          </p>

          <p>
            A sedative and/or analgesic agent should be considered in the
            post-intubation period to prevent discomfort or agitation of the
            patient especially since the medications typically used during RSI
            have a relatively short duration of action compared to the longer
            duration anticipated for the patient to be mechanically ventilated.
          </p>

          <p>
            During this period after the confirmation of your ETT, some patients
            may declare themselves hypotensive or hypoxic. If these do not
            resolved after appropriate treatment of the underlying pathology of
            your critically ill patient, such as with IV fluids or with positive
            pressure ventilation, then the clinician should search for potential
            complications of intubation and ventilation such as
            barotrauma/pneumothorax, ETT cuff rupture or air leak, oxygen
            circuit malfunction, mucus plugging and reconsideration of an
            esophageal intubation.
          </p>
          <StyledLine top />
          <div className="recommended">
            <div className="recommended-label">RECOMMENDED READING</div>
            <RecommendedArticleContainer to="airway/rsi-indications">
              <div className="content-label">ARTICLE</div>
              <h2>
                Indications and Contraindications to Rapid Sequence Intubation
              </h2>
              <p>
                Indications for RSI are simply those patients who cannot
                ventilate, oxygenate or fail to protect their aiway.
                Contraindications are few and relative.
              </p>
            </RecommendedArticleContainer>
            <RecommendedArticleAnchor href="https://amzn.to/2WEky5N">
              <div className="content-label">AFFILIATE LINK</div>
              <h2>The ICU Book</h2>
              <p>
                A fundamental and respected resource in critical care. Including
                a review of various common critical care pathology, ariway and
                mechnical ventilation.
              </p>
            </RecommendedArticleAnchor>
            <RecommendedArticleContainer to="airway/choosing-induction-agent">
              <div className="content-label">ARTICLE</div>
              <h2>Choosing an Induction Agent</h2>
              <p>
                Induction agents used in rapid sequence intubation all lead to a
                similar endpoint which includes providing sedation and amnesia
                and can improve intubating conditions.
              </p>
            </RecommendedArticleContainer>
          </div>
          <StyledLine />
        </Layout>
      </AirwayContainer>
    </>
  )
}

const AirwayContainer = styled.div`
  padding: 0 15px 15px 15px;
  max-width: 1000px;
  margin: auto;
  h3 {
    margin-top: 50px;
  }
  .recommended {
    .recommended-label {
      font-size: 14px;
      margin-top: 0;
      margin-bottom: 35px;
      font-weight: 500;
      font-family: futura-pt, sans-serif;
    }
  }
  .content-label {
    font-family: futura-pt, sans-serif;
    font-size: 12px;
    font-weight: 500;
    line-height: 24px;
    color: gray;
  }
`

const RecommendedArticleAnchor = styled.a`
  text-decoration: none;
  box-shadow: none;
  display: block;
  color: black;
  max-width: 1000px;
  margin: auto;
  margin-bottom: 45px;
  h2 {
    font-size: 21px;
    margin-top: 0;
    margin-bottom: 8px;
  }
  p {
    font-size: 18px;
    line-height: 130%;
    margin-top: 0;
  }
`

const RecommendedArticleContainer = styled(Link)`
  text-decoration: none;
  box-shadow: none;
  display: block;
  color: black;
  max-width: 1000px;
  margin: auto;
  margin-bottom: 45px;
  h2 {
    font-size: 21px;
    margin-top: 0;
    margin-bottom: 8px;
  }
  p {
    font-size: 18px;
    line-height: 130%;
    margin-top: 0;
  }
`

const StyledLine = styled.hr`
  margin-top: ${props => (props.top ? "100px" : "50px")};
`
